/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


#page-content-wrapper{
    width: 100%;
}


#dcload > div > div.col-md-12.col-lg-9{
  background-color: #FFED00;
  font-family: 'Raleway', sans-serif;
}


.btn-primary{
    background-color: #009ADB !important;
    border-color: #009ADB i !important;
}

select {
    background-image:
      linear-gradient(45deg, transparent 50%, blue 50%),
      linear-gradient(135deg, blue 50%, transparent 50%),
      linear-gradient(to right, skyblue, skyblue);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }
  
  select:focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, gray, gray);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }
  
  .logo-mobile{
    display: none;
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    /* width: 100%; */
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    /* margin-right: auto; */
    /* margin-left: auto; */
}

.row {
  --bs-gutter-x: 0 !important;  
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
}

.card-body {
  flex: 1 1 auto;
  padding: 0 !important;
}


@media only screen and (max-width: 600px) {
.d-flex {
    display: flex!important;
    flex-direction: column-reverse!important;
}

.d-block.text-upload.mt-4 {
    display: none!important;
}

#sidebar-wrapper {
    margin-left: 0rem!important;
}

.container-fluid.form-documents {
    margin-left: 13%;
}
#sidebar-wrapper > div.sidebar-heading > img{
  display: none;
}

.logo-mobile{
  display: block;
  margin:0 auto;
}

.container-fluid.form-documents {
  margin-left: 0;
}

.col-lg-9{
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
  font-size: 1.3em;
}


#page-content-wrapper mat-card{
  margin:0 auto;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 auto !important;
}

#dcload > div > div.col-md-12.col-lg-3.pl-0 > div > div > div:nth-child(2) > small{
  font-size: 1em;
  margin: 0 auto;
}

#dcload > div > div.col-md-12.col-lg-3.pl-0 > div > div > div:nth-child(3) > small{
  margin: 0 auto;
}

#dcload > div > div.col-md-12.col-lg-9 > div > div:nth-child(6) > div:nth-child(2) > h4{
  margin-top: 20px;
}

#acabadoTipo > span{
  margin-top:20px;
}


#dcload > div > div.col-md-12.col-lg-9 > div > div:nth-child(6) > div:nth-child(1) > h4{
  margin-top: 20px;
}

}